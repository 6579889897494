import Container from "components/container/container";
import ContentColumn from "components/content-column/content-column";
import Image from "components/image/image";
import NewsletterForm from "components/newsletter-form/newsletter-form";
import SectionTitle from "components/section-title/section-title";
import Section from "components/section/section";
import Text from "components/text/text";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import * as styles from "./newsletter.module.scss";

function Newsletter() {
  const { prismicNewsletter } = useStaticQuery(graphql`
    query NewsletterQuery {
      prismicNewsletter {
        _previewable
        data {
          title
          text {
            html
          }
          cta_newsletter
          success_message
          bg_image {
            thumbnails {
              xs {
                url
              }
              sm {
                url
              }
              md {
                url
              }
              lg {
                url
              }
              xl {
                url
              }
              max {
                url
              }
            }
            dimensions {
              width
              height
            }
          }
        }
      }
    }
  `);
  const data = prismicNewsletter.data;

  return (
    <div className={styles.wrapper}>
      <Image source={data.bg_image} alt="newsletter background" className={styles.bg}></Image>
      <div className={`${styles.section} bg-dark`}>
        <Section>
          <Container>
            <SectionTitle>{data.title}</SectionTitle>
            <ContentColumn>
              {data.text.html && (
                <div className={styles.info}>
                  <Text html={data.text.html}></Text>
                </div>
              )}
              <NewsletterForm
                ctaText={data.cta_newsletter}
                successText={data.success_message}
              ></NewsletterForm>
            </ContentColumn>
          </Container>
        </Section>
      </div>
    </div>
  );
}

export default Newsletter;
