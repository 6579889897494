import React from "react";
import * as styles from "./alert.module.scss";

function Alert({ type, children }) {
  var alertStyle;
  switch (type) {
    case "error":
      alertStyle = styles.error;
      break;
    case "success":
      alertStyle = styles.success;
      break;
    default:
      alertStyle = "";
  }

  return <div className={`${styles.alert} ${alertStyle}`}>{children}</div>;
}

export default Alert;
