// extracted by mini-css-extract-plugin
export var welcomeOuter = "welcome-module--welcome-outer--2TPDR";
export var welcomeInner = "welcome-module--welcome-inner--3t63u";
export var introBlock = "welcome-module--intro-block--2KMgp";
export var introSection = "welcome-module--intro-section--p9BcZ";
export var introImage = "welcome-module--intro-image--3IEnm";
export var image = "welcome-module--image--3X0HH";
export var introBlocks = "welcome-module--intro-blocks--2wbUW";
export var greenBg = "welcome-module--green-bg--1994W";
export var blueBg = "welcome-module--blue-bg--e84p3";
export var goldBg = "welcome-module--gold-bg--3abss";