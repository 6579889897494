import React from "react";
import Button from "../button/button";
import Container from "../container/container";
import Image from "../image/image";
import scrollTo from "gatsby-plugin-smoothscroll";
import * as styles from "./hero.module.scss";

function Hero({ data }) {
  return (
    <div className={styles.heroWrapper}>
      <Image source={data.heroImage} alt="hero" className={styles.heroImage} />

      <Container>
        <div className={styles.heroInner}>
          <div className={`${styles.ctaBlock} bg-dark`}>
            <h1>{data.title}</h1>
            <h2>{data.subtitle}</h2>

            <div className={styles.ctaButtons}>
              <Button to="/aanbod/" size="lg" className={styles.ctaButton}>
                {data.ctaHero}
              </Button>
              <Button
                style="outline"
                size="sm"
                action={() => scrollTo("#newsletter")}
                className={styles.ctaButton}
              >
                <svg
                  height="20"
                  width="20"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ marginRight: "8px" }}
                >
                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                  <polyline points="22,6 12,13 2,6" />
                </svg>
                {data.ctaNewsletter}
              </Button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default Hero;
