import React from "react";
import Image from "../image/image";
import Text from "../text/text";
import * as styles from "./welcome.module.scss";

function Welcome({ data }) {
  return (
    <>
      <div className={styles.welcomeOuter}>
        <div className={`${styles.welcomeInner} ${styles.goldBg} card bg-dark`}>
          <Text html={data.text}></Text>
        </div>
      </div>
      <div className={styles.introSection}>
        <div className={styles.introImage}>
          <Image source={data.profileImage} alt={data.profileName} className={styles.image} />
        </div>
        <div className={styles.introBlocks}>
          <div className={`${styles.introBlock} ${styles.greenBg} card bg-dark`}>
            <Text html={data.introPersonal}></Text>
          </div>
          <div className={`${styles.introBlock} ${styles.blueBg} card bg-dark`}>
            <Text html={data.introOrigin}></Text>
          </div>
        </div>
      </div>
    </>
  );
}

export default Welcome;
