import React from "react";
import Container from "../container/container";
import ContentColumn from "../content-column/content-column";
import Text from "../text/text";
import ImageText from "./image_text/image_text";
import PageTeaser from "./page-teaser/page-teaser";
import Quote from "./quote/quote";
import * as styles from "./slices.module.scss";

const Slices = ({ slices }) => {
  const totalSlices = slices.length;

  return slices.map((slice, index) => {
    const isLastSlice = index + 1 === totalSlices;
    const lastSliceClassName = isLastSlice ? styles.lastSlice : "";
    const isBeforeSectionSlice = slices[index + 1]?.slice_type === "pagina_teaser";
    const beforeSectionSliceClassName = isBeforeSectionSlice ? styles.beforeSectionSlice : "";
    const classNames = `${styles.slice} ${lastSliceClassName} ${beforeSectionSliceClassName}`;
    const sectionClassNames = `${styles.sectionSlice} ${lastSliceClassName}`;

    switch (slice.slice_type) {
      case "text":
        return (
          <div key={index} className={classNames}>
            <Container>
              <ContentColumn>
                <Text html={slice.primary.text.html} />
              </ContentColumn>
            </Container>
          </div>
        );

      case "image_text":
        return (
          <div key={index} className={classNames}>
            <Container>
              <ContentColumn>
                <ImageText slice={slice} />
              </ContentColumn>
            </Container>
          </div>
        );

      case "quote":
        return (
          <div key={index} className={classNames}>
            <Quote html={slice.primary.quote.html} />
          </div>
        );

      case "pagina_teaser":
        return (
          <div key={index} className={sectionClassNames}>
            <PageTeaser slice={slice}></PageTeaser>
          </div>
        );
    }
  });
};

export default Slices;
