import PropTypes from "prop-types";
import React from "react";
import * as styles from "./text.module.scss";

const Text = (props) => (
  <div className={styles.text} dangerouslySetInnerHTML={{ __html: props.html }}></div>
);

Text.propTypes = {
  html: PropTypes.string,
};

export default Text;
