import Alert from "components/alert/alert";
import Button from "components/button/button";
import NeedsJs from "components/needs-js/needs-js";
import React, { useState } from "react";
import * as styles from "./newsletter-form.module.scss";

function NewsletterForm({ ctaText, successText }) {
  const [form, setForm] = useState({ name: "", email: "" });
  const [validations, setValidations] = useState({ name: "", email: "" });
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  function handleChange(event) {
    event.preventDefault();
    setSubmitError(false);
    setSubmitSuccess(false);
    const { name, value } = event.target;
    form[name] = value;
    setForm({ ...form });
    if (submitted) validate();
  }

  function validate() {
    validations.name = form.name.length == 0 ? "Ongeldige naam" : "";
    validations.email = form.email.length == 0  ? "Ongeldig e-mailadres" : "";
    setValidations({ ...validations });
  }

  function isValid() {
    return validations.name === "" && validations.email === "";
  }

  async function onSubmit(e) {
    if (loading) return;
    e.preventDefault();
    setSubmitted(true);
    validate();
    if (isValid()) {
      setLoading(true);
      await sendEmail();
      setLoading(false);
    }
  }

  async function sendEmail() {
    var endpoint = "/.netlify/functions/subscribe";
    var headers = {
      method: "POST",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(form),
    };

    const response = await fetch(endpoint, headers)
      .then(async (response) => {
        if (response.status !== 200) {
          setSubmitError(true);
          return await response.json();
        }
        reset();
        setSubmitSuccess(true);
        return await response.json();
      })
      .catch((error) => {
        setSubmitError(true);
        return error;
      });

    console.log(response);
  }

  function reset() {
    setForm((form) => {
      form.name = "";
      form.email = "";
      return form;
    });
    setSubmitted(false);
  }

  return (
    <div className={`${styles.newsletterForm} card`}>
      {!submitSuccess && (
        <>
          <NeedsJs>
            <form onSubmit={onSubmit} method="POST" action="#">
              <div
                className={`${styles.formGroup} ${validations.name.length > 0 ? styles.error : ""}`}
              >
                <label htmlFor="newsletter-name">Naam:</label>
                <input
                  type="text"
                  id="newsletter-name"
                  name="name"
                  value={form.name || ""}
                  onChange={handleChange}
                  disabled={loading}
                />
                <div className={styles.validationMessage}>
                  {validations.name.length > 0 && `* ${validations.name}`}
                </div>
              </div>

              <div
                className={`${styles.formGroup} ${
                  validations.email.length > 0 ? styles.error : ""
                }`}
              >
                <label htmlFor="newsletter-email">E-mail:</label>
                <input
                  type="mail"
                  id="newsletter-email"
                  name="email"
                  value={form.email || ""}
                  onChange={handleChange}
                  disabled={loading}
                />
                <div className={styles.validationMessage}>
                  {validations.email.length > 0 && `* ${validations.email}`}
                </div>
              </div>

              {submitError && (
                <div className={styles.formGroup}>
                  <Alert type="error">
                    Het abonneren is niet gelukt, onze excuses. Indien het probleem zich blijft
                    voordoen, kan je jouw inschrijving ook rechtstreeks aanvragen via{" "}
                    <a href="mailto:info@sonja-vervoort.be">info@sonja-vervoort.be</a>.
                  </Alert>
                </div>
              )}

              {!submitSuccess && !submitError && (
                <Button type="submit" size="lg" loading={loading}>
                  {ctaText}
                </Button>
              )}
            </form>
          </NeedsJs>
        </>
      )}

      {submitSuccess && (
        <div className={styles.formGroup}>
          <Alert type="success">{successText}</Alert>
        </div>
      )}
    </div>
  );
}

export default NewsletterForm;
