import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Slider from "../slider/slider";
import * as styles from "./testimonials.module.scss";

function Testimonials() {
  const { allPrismicTestimonial } = useStaticQuery(graphql`
    query TestomonialsQuery {
      allPrismicTestimonial {
        nodes {
          data {
            name
            testimonial {
              html
            }
          }
          _previewable
        }
      }
    }
  `);
  const testimonials = allPrismicTestimonial.nodes.map((x) => {
    return { name: x.data.name, review: x.data.testimonial.html };
  });

  return (
    <div className={styles.testimonials}>
      <Slider>
        {testimonials.map((testimonial, index) => {
          return (
            <div key={index} className={styles.testimonial}>
              <div
                className={styles.speech}
                dangerouslySetInnerHTML={{ __html: testimonial.review }}
              ></div>
              <div className={styles.name}>{testimonial.name || "anoniem"}</div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default Testimonials;
