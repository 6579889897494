import React from "react";
import Image from "../../image/image";
import Text from "../../text/text";
import * as styles from "./image_text.module.scss";

function ImageText({ slice }) {
  const x = slice.primary;
  let variant = styles.imageLeft;
  if (x.variant === "Afbeelding rechts") {
    variant = styles.imageRight;
  }

  return (
    <div className={`${styles.imageText} ${variant}`}>
      <div className={styles.imageBlock}>
        <Image source={x.image} className={styles.image}></Image>
      </div>
      <div className={styles.text}>
        <Text html={x.text.html}></Text>
      </div>
    </div>
  );
}

export default ImageText;
