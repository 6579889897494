import React from "react";
import Button from "../../button/button";
import Container from "../../container/container";
import Image from "../../image/image";
import Section from "../../section/section";
import Text from "../../text/text";
import * as styles from "./page-teaser.module.scss";

function PageTeaser({ slice }) {
  const data = slice.primary;
  let variant = styles.imageLeft;
  if (data.variant === "Afbeelding rechts") {
    variant = styles.imageRight;
  }

  return (
    <Section bg="blue">
      <Container>
        <div className={`${styles.container} ${variant}`}>
          {data.image?.url && (
            <div className={styles.imageBlock}>
              <Image source={data.image} className={styles.image} />
            </div>
          )}
          <div className={`${styles.textBlock} card`}>
            {data.title && <h2>{data.title}</h2>}
            <div className={styles.text}>
              <Text html={data.text.html}></Text>
            </div>
            <Button to={data.page_link.url}>{data.cta}</Button>
          </div>
        </div>
      </Container>
    </Section>
  );
}

export default PageTeaser;
