import { Link } from "gatsby";
import React from "react";
import * as styles from "./button.module.scss";

function Button(props) {
  let buttonType = props.type;
  if (props.action) buttonType = "button";
  else if (props.to) buttonType = "link";

  let buttonSize;
  switch (props.size) {
    case "sm":
      buttonSize = styles.sm;
      break;
    case "lg":
      buttonSize = styles.lg;
      break;
    default:
      buttonSize = "";
  }

  let buttonStyle;
  switch (props.style) {
    case "primary":
      buttonStyle = styles.primary;
      break;
    case "outline":
      buttonStyle = styles.outline;
      break;
    default:
      buttonStyle = styles.primary;
  }

  let classNames = `${styles.btn} ${buttonStyle} ${buttonSize} ${props.className || ""}`;

  if (buttonType === "submit") {
    return (
      <button type="submit" disabled={props.disabled || props.loading} className={classNames}>
        <span className={styles.center}>
          {props.loading && <span className={styles.spinner}></span>}
          <span>{props.children}</span>
        </span>
      </button>
    );
  }

  if (buttonType === "button") {
    return (
      <button type="button" onClick={() => props.action()} className={classNames}>
        <span className={styles.center}>{props.children}</span>
      </button>
    );
  }

  if (buttonType === "link") {
    return (
      <Link to={props.to} className={classNames}>
        <span className={styles.center}>{props.children}</span>
      </Link>
    );
  }

  return "";
}

export default Button;
