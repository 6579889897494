import Newsletter from "components/newsletter/newsletter";
import Schedule from "components/schedule/schedule";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import React from "react";
import { repositoryConfig } from "utils/repositoryConfig";
import Button from "../components/button/button";
import Container from "../components/container/container";
import Hero from "../components/hero/hero";
import SectionTitle from "../components/section-title/section-title";
import Section from "../components/section/section";
import SEO from "../components/seo";
import Quote from "../components/slices/quote/quote";
import Slices from "../components/slices/slices";
import Testimonials from "../components/testimonials/testimonials";
import ThumbGrid from "../components/thumb-grid/thumb-grid";
import Welcome from "../components/welcome/welcome";

function IndexPage({ data }) {
  var pageData = data.prismicHomePage.data;

  var heroData = {
    title: pageData.hero_title,
    subtitle: pageData.hero_subtitle,
    heroImage: pageData.hero_image,
    ctaHero: pageData.cta_hero,
    ctaNewsletter: pageData.cta_newsletter,
  };

  var welcomeData = {
    title: pageData.welcome_title,
    text: pageData.welcome_text.html,
    profileImage: pageData.profile_image_large,
    profileName: pageData.profile_name,
    introPersonal: pageData.intro_personal.html,
    introOrigin: pageData.intro_origin.html,
  };

  const aanbodItems = data.allPrismicAanbodItem.nodes.map((node) => {
    return {
      key: node.uid,
      title: node.data.title.text,
      link: `/aanbod/item/${node.uid}/`,
      image: node.data.image,
    };
  });

  const scheduleItems = data.schedule.nodes;

  return (
    <>
      <SEO title="Home" />
      <Hero data={heroData}></Hero>

      <Container>
        <Welcome data={welcomeData} />
      </Container>

      <Schedule items={scheduleItems}></Schedule>

      <Section bg="blue">
        <Container>
          <Testimonials></Testimonials>
        </Container>
      </Section>

      <Section>
        <Container center>
          <SectionTitle>{pageData.aanbod_title}</SectionTitle>
          <ThumbGrid items={aanbodItems}></ThumbGrid>
          <Button to="/aanbod/">{pageData.cta_aanbod}</Button>
        </Container>
      </Section>

      <Slices slices={pageData.body}></Slices>

      <div id="newsletter">
        <Newsletter></Newsletter>
      </div>

      {/* <Section>
        <Container>
          <SectionTitle>Recente artikelen</SectionTitle>
        </Container>
      </Section> */}

      <Quote html={pageData.bottom_quote}></Quote>
    </>
  );
}

export const Query = graphql`
  {
    prismicHomePage {
      _previewable
      data {
        hero_subtitle
        hero_title
        hero_image {
          thumbnails {
            xs {
              url
            }
            sm {
              url
            }
            md {
              url
            }
            lg {
              url
            }
            xl {
              url
            }
            max {
              url
            }
          }
          dimensions {
            width
            height
          }
        }
        welcome_text {
          html
        }
        cta_hero
        cta_newsletter
        profile_image_large {
          thumbnails {
            xs {
              url
            }
            xs_2x {
              url
            }
            xs_3x {
              url
            }
            sm {
              url
            }
            sm_2x {
              url
            }
            sm_3x {
              url
            }
          }
          url
          alt
          dimensions {
            width
            height
          }
        }
        intro_origin {
          html
        }
        intro_personal {
          html
        }
        aanbod_title
        cta_aanbod
        bottom_quote
        body {
          ... on PrismicHomePageDataBodyPaginaTeaser {
            id
            slice_type
            primary {
              page_link {
                uid
                type
                url
              }
              title
              cta
              text {
                html
              }
              variant
              image {
                thumbnails {
                  xs {
                    url
                  }
                  xs_2x {
                    url
                  }
                  xs_3x {
                    url
                  }
                  sm {
                    url
                  }
                  sm_2x {
                    url
                  }
                  sm_3x {
                    url
                  }
                }
                alt
                url
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
    allPrismicAanbodItem(
      filter: { data: { in_the_spotlight: { eq: true } } }
      sort: { fields: data___priority }
    ) {
      nodes {
        data {
          title {
            text
          }
          image {
            url
            thumbnails {
              xs {
                url
              }
              xs_2x {
                url
              }
              xs_3x {
                url
              }
            }
            dimensions {
              width
              height
            }
          }
        }
        uid
        _previewable
      }
    }
    schedule: allPrismicAanbodItem {
      nodes {
        uid
        url
        data {
          title {
            text
          }
          page_description
          category {
            slug
          }
          body1 {
            ... on PrismicAanbodItemDataBody1EnkeleSessie {
              slice_type
              primary {
                date: date(locale: "nl-BE")
                day: date(formatString: "DD", locale: "nl-BE")
                month: date(formatString: "M", locale: "nl-BE")
                year: date(formatString: "YYYY", locale: "nl-BE")
                day_str: date(formatString: "dd", locale: "nl-BE")
                month_str: date(formatString: "MMM", locale: "nl-BE")
                end_hour
                end_minutes
                start_hour
                start_minutes
                remark
              }
            }
            ... on PrismicAanbodItemDataBody1MeerdereSessies {
              slice_type
              items {
                date: date(locale: "nl-BE")
                day: date(formatString: "DD", locale: "nl-BE")
                month: date(formatString: "M", locale: "nl-BE")
                year: date(formatString: "YYYY", locale: "nl-BE")
                day_str: date(formatString: "dd", locale: "nl-BE")
                month_str: date(formatString: "MMM", locale: "nl-BE")
                end_hour
                end_minutes
                start_hour
                start_minutes
                remark
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(IndexPage, repositoryConfig);
