exports.linkResolver = (doc) => {
  if (doc.type === "home_page") {
    return "/";
  }

  if (doc.type === "contact_page") {
    return "/contact/";
  }

  if (doc.type === "page") {
    return `/${doc.uid}/`;
  }

  if (doc.type === "aanbod_category") {
    return `/aanbod/categorie/${doc.uid}/`;
  }

  if (doc.type === "aanbod_item") {
    return `/aanbod/item/${doc.uid}/`;
  }

  return "/404/";
};
