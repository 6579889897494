import PropTypes from "prop-types";
import React from "react";
import Container from "../../container/container";
import ContentColumn from "../../content-column/content-column";
import * as styles from "./quote.module.scss";

const Quote = ({ html }) => (
  <div className={styles.quote}>
    <Container>
      <ContentColumn>
        <div className={styles.innerQuote}>
          <svg viewBox="0 0 729 518" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g transform="matrix(4.16667,0,0,4.16667,-512.933,-535.563)">
              <path d="M295.279,222.584C295.31,230.978 291.862,238.055 284.934,243.805C278.006,249.565 269.644,252.452 259.872,252.474C250.089,252.496 241.838,249.647 235.134,243.917C228.418,238.198 224.785,231.137 224.247,222.744C224.657,196.244 232.178,174.925 246.789,158.766C261.4,142.616 274.609,132.536 286.441,128.535L298.063,139.77C271.866,154.404 256.743,172.107 252.706,192.869C253.731,192.867 254.888,192.761 256.178,192.53C257.456,192.31 258.614,192.193 259.651,192.191C269.423,192.169 277.806,195.131 284.777,201.077C291.748,207.024 295.248,214.2 295.279,222.584ZM194.136,222.812C194.167,231.206 190.718,238.283 183.79,244.033C176.863,249.793 168.502,252.68 158.73,252.702C148.945,252.724 140.696,249.875 133.991,244.145C127.274,238.426 123.641,231.365 123.104,222.972C123.513,196.472 131.035,175.153 145.646,158.994C160.256,142.844 173.466,132.764 185.298,128.763L196.92,139.998C170.723,154.632 155.599,172.334 151.561,193.097C152.587,193.095 153.745,192.989 155.035,192.758C156.313,192.538 157.47,192.421 158.508,192.419C168.28,192.396 176.663,195.359 183.633,201.305C190.604,207.252 194.105,214.428 194.136,222.812Z" />
            </g>
          </svg>
          <blockquote className={styles.quote} dangerouslySetInnerHTML={{ __html: html }}></blockquote>
        </div>
      </ContentColumn>
    </Container>
  </div>
);

Quote.propTypes = {
  html: PropTypes.string,
};

export default Quote;
