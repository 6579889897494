import Alert from "components/alert/alert";
import React from "react";
import { Helmet } from "react-helmet";

function NeedsJs({ children }) {
  return (
    <>
      <Helmet>
        <noscript>{`<style>.hide-if-no-js { display: none !important; }</style>`}</noscript>
      </Helmet>
      <noscript>
        <Alert type="error">
          Opgelet, javascript is uitgeschakeld. Schakel javascript in voor een optimale werking van
          de website.
        </Alert>
      </noscript>
      <div className="hide-if-no-js">{children}</div>
    </>
  );
}

export default NeedsJs;
